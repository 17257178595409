<template>
  <div id="app">
    <div class="nav">
      <div class="width">
        <img class="logo" src="https://oss.topfavor.cn/assets/pc/logo.png" />
        <img class="ceen" src="https://oss.topfavor.cn/assets/pc/cnen.png" />
        <img class="car" src="https://oss.topfavor.cn/assets/pc/car.png" />
        <img class="line" src="https://oss.topfavor.cn/assets/pc/line.png" />
        <img class="xin" src="https://oss.topfavor.cn/assets/pc/xin.png" />
        <img class="sreach" src="https://oss.topfavor.cn/assets/pc/sreach.png" />
        <div class="links">
          <router-link to="/"  @click.native="changeM1"><img class="img_h" src="https://oss.topfavor.cn/assets/pc/home.png"/></router-link>
          <a :class="{'router-link-exact-active': !isM}"  @mouseleave="isShow1 = false"  @mouseenter="isShow1 = true"  @click="changeM"><img class="img_h"   src="https://oss.topfavor.cn/assets/pc/product.png"/>
            <div class="product_NAV" @click="toShop" :class="{active: isShow1, isShow: isShow1}">
              <div class="flex">
                <div>CHASING</div>
                <div>STARTS</div>
              </div>
            </div>
          </a>
          <router-link @click.native="changeM1" to="/about"><img class="img_a" src="https://oss.topfavor.cn/assets/pc/about.png"/></router-link>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="main">
        <router-view/>
      </div>
      <div class="footer" v-show="isShow">
        <div class="width">
            <img class="footer_logo" src="https://oss.topfavor.cn/assets/pc/footer_logo.png" />
            <img class="footer_icon" src="https://oss.topfavor.cn/assets/pc/footer_icon.png" />
            <img class="footer_icon_01" src="https://oss.topfavor.cn/assets/pc/footer_icon_01.png" />
            <img class="footer_icon_02" src="https://oss.topfavor.cn/assets/pc/footer_icon_02.png" />
            <img class="footer_icon_03" src="https://oss.topfavor.cn/assets/pc/footer_icon_03.png" />
            <img class="footer_icon_04" src="https://oss.topfavor.cn/assets/pc/footer_icon_04.png" />
            <img class="footer_icon_05" src="https://oss.topfavor.cn/assets/pc/busuness.png" />
            <img class="footer_icon_06" src="https://oss.topfavor.cn/assets/pc/new_01.png" />
            <img class="footer_icon_07" src="https://oss.topfavor.cn/assets/pc/quick.png" />
            <img class="footer_icon_08" src="https://oss.topfavor.cn/assets/pc/footer_email.png" />
            <img class="footer_icon_09" src="https://oss.topfavor.cn/assets/pc/news.png" />
            <img class="footer_icon_10" src="https://oss.topfavor.cn/assets/pc/sc.png" />
            <img class="footer_icon_11" @click="to" src="./static/001.png"  />
            <img class="footer_icon_12" @click="toPortal" src="./static/002.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    data() {
      return {
        isShow: false,
        isShow1: false,
        isShow2: false
      }
    },
    computed: {
      isM() {
        return this.$store.state.isM
      }
    },
    mounted() {
      this.isShow = true
      // console.log(this.$store)
    },
    methods: {
      changeM() {
        this.$store.commit('changeM', false)
      },
      changeM1() {
        this.$store.commit('changeM', true)
      },
      toPortal() {
       window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012183", '_blank'); 
    
      },
      to() {
       // window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012183", '_blank'); 
       window.open("https://beian.miit.gov.cn", '_blank')
      },
      toShop() {
        this.$router.push({path:'/shop'})
        this.$store.commit('changeM2', false)
      }
    }
  }
</script>
<style lang="scss">
  @font-face {
  font-family: 'Sans_SC_Bold';
  src: url('https://oss.topfavor.cn/assets/HarmonyOS_Sans_SC_Bold.ttf');
}
@font-face {
  font-family: 'Sans_SC_Light';
  src: url('https://oss.topfavor.cn/assets/HarmonyOS_Sans_SC_Light.ttf');
}
.product_NAV {
  position: absolute;
  top: 0.48rem;
  left: 0.74rem;
  width: 0.74rem;
  height: 0.42rem;
  text-align: center;
  font-size: 0.12rem;
  line-height: 0.14rem;
  color: #333;
  display: none;
  .flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  &.active {
    background-color: #EFEFEF;
  }
}
html, body, #app{
  min-height: 100%;
  font-size: 16px;
}
.main {
  min-height: 100vh;
}
.body {
  padding-top: 0.48rem;
  box-sizing: border-box;
  min-width: 0.96rem;
  // padding-bottom: 2rem;
}
.nav {
  height: 0.48rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 10;
  .links {
    position: absolute;
    top: 0;
    left: 0.7rem;
  }
  a {
    line-height: 0.48rem;
    color: #333;
    display: inline-block;
    font-size: 0.14rem;
    width: 0.74rem;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    &.router-link-exact-active {
      background-color: #EFEFEF;
      .isShow {
        display: block;
      }
    }
  }
  .logo {
    width: 0.44rem;
    height: 0.23rem;
    margin-top: 0.13rem;
  }
  .ceen {
    position: absolute;
    right: 0;
    width: 0.25rem;
    height: 0.07rem;
    top: 0.21rem;
  }
  .img_h{
    width: 0.2rem;
    height: 0.1rem;
  }
  .img_a{
    width: 0.4rem;
    height: 0.1rem;
  }
  .car {
    position: absolute;
    right: 0.5rem;
    top: 0.19rem;
    width: 0.12rem;
    height: 0.11rem;
  }
  .line {
    position: absolute;
    right: 0.72rem;
    top: 0.19rem;
    width: 0.27rem;
    height: 0.11rem;
  }
  .xin {
    position: absolute;
    right: 0.8rem;
    top: 0.2rem;
    width: 0.11rem;
    height: 0.10rem;
  }
  .sreach {
    position: absolute;
    right: 1.09rem;
    top: 0.2rem;
    width: 0.08rem;
    height: 0.08rem;
  }
}
.width {
  width: 9.6rem;
  margin: 0 auto;
  position: relative;
}
.footer {
  height: 2rem;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  background-color: #3D3A3A;
  .footer_logo {
    position: absolute;
    width: 1.4rem;
    height: 0.2rem;
    left: 1.89rem;
    top: 0.425rem;
  }
  .footer_icon {
    position: absolute;
    width: 0.54rem;
    height: 0.08rem;
    left: 1.91rem;
    top: 1.025rem;
  }
  .footer_icon_01 {
    position: absolute;
    width: 0.125rem;
    height: 0.11rem;
    left: 1.91rem;
    top: 1.305rem;
  }
  .footer_icon_02 {
    position: absolute;
    width: 0.12rem;
    height: 0.1rem;
    left: 2.3rem;
    top: 1.31rem;
  }
  .footer_icon_03 {
    position: absolute;
    width: 0.085rem;
    height: 0.095rem;
    left: 2.68rem;
    top: 1.315rem;
  }
  .footer_icon_04 {
    position: absolute;
    width: 0.12rem;
    height: 0.12rem;
    left: 3.01rem;
    top: 1.3rem;
  }
  .footer_icon_05 {
    position: absolute;
    width: 0.91rem;
    height: 0.08rem;
    left: 4.315rem;
    top: 0.51rem;
  }
  .footer_icon_06 {
    position: absolute;
    width: 0.6rem;
    height: 0.08rem;
    left: 5.865rem;
    top: 0.51rem;
  }
  .footer_icon_07 {
    position: absolute;
    width: 0.67rem;
    height: 0.095rem;
    left: 7.105rem;
    top: 0.495rem;
  }
  .footer_icon_08 {
    position: absolute;
    width: 0.73rem;
    height: 0.085rem;
    left: 4.32rem;
    top: 0.755rem;
  }
  .footer_icon_09 {
    position: absolute;
    width: 0.425rem;
    height: 0.07rem;
    left: 5.87rem;
    top: 0.76rem;
  }
  .footer_icon_10 {
    position: absolute;
    width: 0.555rem;
    height: 0.07rem;
    left: 7.105rem;
    top: 0.76rem;
  }
  .footer_icon_11 {
    position: absolute;
    width: 2.36rem;
    height: 0.075rem;
    left: 4.315rem;
    top: 1.31rem;
    cursor: pointer;
  }
  .footer_icon_12 {
    position: absolute;
    width: 1.065rem;
    height: 0.075rem;
    left: 6.7rem;
    top: 1.31rem;
    cursor: pointer;
  }
}
</style>